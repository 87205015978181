import { Button, Card, Col, PageHeader, Row, Empty } from "antd";
import Meta from "antd/lib/card/Meta";
import * as PropTypes from "prop-types";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useHistory, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { addProduct, Product, selectCart, selectCategoryInCart, selectPeriod } from "../../reducers/cartSlice";
import { Category, CategoryWithPrices, searchCategories, selectCategoriesWithPrices, selectCategory } from "../../reducers/categoriesSlice";
import { Price } from "./Price";

Col.propTypes = {
    span: PropTypes.number,
    children: PropTypes.node,
};

export const Categories = () => {
    const categories = useAppSelector(selectCategoriesWithPrices);
    const { startDate, endDate, location, products } = useAppSelector(selectCart);
    const history = useHistory();
    const dispatch = useAppDispatch();
    const period = useAppSelector(selectPeriod);

    useEffect(() => {
        if (location && startDate && endDate) {
            dispatch(searchCategories({ location: location.id, pickup_date: [startDate, endDate] }));
        }

    }, [dispatch, location, startDate, endDate]);

    const handleAddToCart = (categoryId: string) => () => {
        dispatch(addProduct(categoryId));
    }

    const actions = (category: Category) => {
        const actions = [];
        if (location && startDate && endDate) {
            actions.push(<Button type="primary" onClick={handleAddToCart(category.id)} disabled={categoryNotAvailable(products.find(product => product.id === category.id), category)}><FormattedMessage id="app.categories.addToCart" /></Button>)
        }
        return actions;
    }

    return (
        <div className="categories-wrapper">
            <Row gutter={[24, 12]}>
                {categories.length > 0 ? categories.map((category: CategoryWithPrices, index: number) => (
                    <Col xs={24} md={4} key={category.id}>
                        <Card
                            actions={actions(category)}
                            key={index}
                            hoverable
                            title={category.attributes.name}
                            cover={<img alt="example" src={category.attributes.photo ?? "https://via.placeholder.com/150"} onClick={() => history.push(`/categories/${category.id}`)} />}
                        >
                            <Meta title={<Link to={{
                                pathname: `/categories/${category.id}`,
                            }}>{category.attributes.name}</Link>}
                                description={<Price price={category.prices.find(price => price.attributes.period === period)?.attributes.price} />} />
                        </Card>
                    </Col>
                )
                ) : <Col xs={24} md={24}><Empty
                    description={
                        <FormattedMessage
                            id="app.search.emptyLocation"
                            defaultMessage="There are no bikes in the selected location"
                        />
                    }
                />
                </Col>
                }
            </Row>
        </div>
    );
}

export const CategoryComponent = () => {
    const { categoryId } = useParams<{ categoryId: string; }>();
    const history = useHistory();
    const category = useAppSelector(selectCategory(categoryId));
    const period = useAppSelector(selectPeriod);
    const categoryInCart = useAppSelector(selectCategoryInCart(categoryId));
    const dispatch = useAppDispatch();
    const handleAddToCart = () => {
        dispatch(addProduct(categoryId));
    }

    return (category && <>
        <PageHeader
            className="site-page-header"
            onBack={() => history.push('/')}
            title={category.attributes.name}
            subTitle="This is a subtitle"
        />
        {category === null && <p>Category does not exist</p>}
        {category && <Row gutter={24}>
            <Col>
                <img className="category-image" src={category.attributes.photo ?? "https://via.placeholder.com/150"} alt={category.attributes.name} />
            </Col>
            <Col>
                <p>{category.attributes.name}</p>
                <p><Price price={category.prices.find(price => price.attributes.period === period)?.attributes.price} /></p>
                <Button type="primary" onClick={handleAddToCart} disabled={categoryNotAvailable(categoryInCart, category)}><FormattedMessage id="app.categories.addToCart" /></Button>
            </Col>
        </Row>}
    </>);
}

const categoryNotAvailable = (categoryInCart: Product | undefined, category: Category) => {
    return (categoryInCart?.amount ?? 0) === category.attributes.available;
}

