import React from "react";
import {Select} from 'antd';
import {useAppDispatch, useAppSelector} from "../../hooks";
import {selectLanguage, selectLocale} from "../../reducers/localeSlice";

const {Option} = Select;

const LanguagePicker = () => {
  const {locale} = useAppSelector(selectLocale);
  const dispatch = useAppDispatch();
  const handleSelect = (locale: string) => {
    dispatch(selectLanguage(locale))
  };

  return (
    <Select defaultValue={locale} style={{width: 120}} bordered={false} onSelect={handleSelect}>
      <Option value="pl">POLSKI</Option>
      <Option value="en">ENGLISH</Option>
    </Select>
  )

}

export default LanguagePicker;