import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import 'antd/dist/antd.css';
import './styles/main.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';

Sentry.init({
    dsn: "https://22ed93eb9e474461a2e9a9024a75014e@o393838.ingest.sentry.io/5689790",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1,
});

const persistor = persistStore(store);

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();
