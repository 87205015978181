import { Col, Menu, Row } from "antd";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

export const DetailsFooter = () => {
    return (
        <div className="details-footer">
            <Row>
                <Col xs={24} md={8}>
                    <Link to="/" className="logo-name">ebike24.pl</Link>
                </Col>
                <Col xs={24} md={8}>
                    <div>
                        <p><strong>ADRES</strong><br />Gdańsk, ul. Kotwiczników 20 / Garaż 1<br /></p>
                        <p><strong>TELEFON</strong><br /><a href="tel:+48661080123">+48 661-080-123</a><br /></p>
                        <p>
                            <a href="tel:+48698674887">+48 698-674-887</a><br />
                            <strong>EMAIL</strong><br /><a href="mailto:biuro@segway24.pl">biuro@segway24.pl</a>
                        </p>
                    </div>
                </Col>
                <Col xs={24} md={8}>
                <Menu theme="light" mode="inline" inlineIndent={0} style={{borderRight: 0}}>
                    <Menu.Item key="1"><a target="_top" href="https://ebike24.pl/kontakt/"><FormattedMessage id="app.menu.contact" /></a></Menu.Item>
                    <Menu.Item key="2"><a target="_top" href="https://ebike24.pl/polityka-prywatnosci/"><FormattedMessage id="app.menu.privacyPolicy" /></a></Menu.Item>
                    <Menu.Item key="3"><a target="_blank" rel="noreferrer" href="https://ebike24.pl/dokumenty/Og%C3%B3lneWarunkiWynajmuEBIKE24.pdf"><FormattedMessage id="app.menu.termsAndConditions" /></a></Menu.Item>
                    <Menu.Item key="4"><a target="_blank" rel="noreferrer" href="https://ebike24.pl/dokumenty/UmowaEBIKE24.pdf"><FormattedMessage id="app.menu.contract" /></a></Menu.Item>
                </Menu>
                </Col>
            </Row>
        </div>
    );
}
