import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Order } from "../../api/orders";

const renderAsPrice = (mini: number): string => {
    const priceAsString = String(mini);

    return `${priceAsString.substring(0, (priceAsString.length - 2))}.${priceAsString.substring(priceAsString.length - 2)} `;
}

export const OrderSummary: FC<{ order: Order }> = ({ order }) => {
    return (<>
        <h1><FormattedMessage id="app.order.summary" /></h1>
        <ul>
            <li>Order ID: {order.id}</li>
            <li>{order.attributes.firstName} {order.attributes.lastName}</li>
            <li>{order.attributes.street}, {order.attributes.zipCode} {order.attributes.city}</li>
            <li><FormattedMessage id="app.bookings.price" />: {renderAsPrice(order.attributes.price.amount)} {order.attributes.price.currency}</li>
        </ul>
    </>);
}
