import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from "redux";
import cartReducer from './reducers/cartSlice';
import categoriesReducer from './reducers/categoriesSlice';
import localeReducer from './reducers/localeSlice';

import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

const reducers = combineReducers({
    cart: cartReducer,
    categories: categoriesReducer,
    locale: localeReducer,
});

export type RootState = ReturnType<typeof reducers>;

const persistConfig = {
    key: 'root',
    storage
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
})

export default store
