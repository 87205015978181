import React from "react";

import { Layout } from 'antd';
import SearchBar from "./components/search";

import { BrowserRouter, Link, Route, Switch } from "react-router-dom";
// import { Bookings } from "./components/booking";
import { BookingForm } from "./components/booking/BookingForm";
import { BookingComponent } from "./components/booking/Booking";
import { Categories, CategoryComponent } from "./components/categories";
import { useAppSelector } from "./hooks";
import polish from "./lang/pl.json";
import pl from "antd/lib/locale/pl_PL";
import english from './lang/en.json';
import en from 'antd/lib/locale/en_US';

import { IntlProvider } from 'react-intl';
import { ConfigProvider } from 'antd';
import { selectLocale } from "./reducers/localeSlice";
import { OrderForm } from "./components/order";
import { MainHeader } from "./components/header/MainHeader";
import { DetailsFooter } from "./components/footer/DetailsFooter";

const { Content, Footer } = Layout;

export const FOOTER_TEXT = "made with ♥ in Gdynia";

const App = () => {
    const { locale } = useAppSelector(selectLocale);

    // ConfigProvider.config({
    //     theme: {
    //         primaryColor: '#e6373d',
    //         errorColor: '#ff4d4f',
    //         warningColor: '#faad14',
    //         successColor: '#52c41a',
    //         infoColor: '#1890ff',
    //     }
    // })

    return (
        <IntlProvider messages={locale === 'pl' ? polish : english} locale={locale} defaultLocale="en">
            <ConfigProvider locale={locale === 'pl' ? pl : en} >
                <BrowserRouter>
                    <Layout className="site-layout">
                        <MainHeader />
                        <Content style={{ padding: '0 50px' }}>
                            <Switch>
                                <Route path="/book">
                                    <BookingForm />
                                </Route>
                                <Route path="/order">
                                    <OrderForm />
                                </Route>
                                {/* <Route path="/account/bookings">
                                    <Bookings />
                                </Route> */}
                                <Route path="/bookings/:bookingId">
                                    <BookingComponent />
                                </Route>
                                {/* <Route path="/account">
                                    <p>Register or Login</p>
                                    <Link to="/account/bookings">Bookings</Link>
                                </Route> */}
                                <Route path="/contact">
                                    <p>Get in touch!</p>
                                </Route>
                                <Route path="/categories/:categoryId">
                                    <CategoryComponent />
                                </Route>
                                <Route path="/">
                                    <SearchBar />
                                    <Categories />
                                </Route>
                            </Switch>
                        </Content>
                        <DetailsFooter />
                        <Footer style={{ textAlign: 'center' }}>
                            <>{"© "}</><Link to="/">ebike24.pl</Link><>{`/ ${FOOTER_TEXT} / `}</><Link to={"https://is8.pl"}>is8.pl</Link>
                        </Footer>
                    </Layout>
                </BrowserRouter>
            </ConfigProvider>
        </IntlProvider>
    );
}

export default App;
