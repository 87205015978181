import React, { FC } from "react";

import { FormattedMessage } from "react-intl";
import { Booking } from "../../api/bookings";
import { useAppSelector } from "../../hooks";
import { Category, selectCategories } from "../../reducers/categoriesSlice";

export const BookingDetails: FC<{ booking: Booking }> = ({ booking }) => {
  const categories = useAppSelector(selectCategories);

  return (
    <>
      <h1><FormattedMessage id="app.booking.details" /></h1>
      <p>
        <FormattedMessage id="app.booking.bookingId" />: {booking.id}
      </p>
      <p>
        <FormattedMessage id="app.booking.startDate" />: {booking.attributes.start_date}
      </p>
      <p>
        <FormattedMessage id="app.booking.endDate" />: {booking.attributes.end_date}
      </p>
      <p><FormattedMessage id="app.booking.details" />:</p>
      <ul>
        {booking.relationships.categories.data.map(product => {
          const category = categories.categories.find((category: Category) => category.id === String(product.id));
          return <li key={product.id}>{category ? category.attributes.name : product.id}</li>
        }
        )}
      </ul>
    </>);
}