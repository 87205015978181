import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import { Booking, getBookingWithOrder } from "../../api/bookings";
import { OrderForm } from "../order";
import { FormattedMessage } from "react-intl";

import { BookingDetails } from "./BookingDetails";
import { resetCart } from '../../reducers/cartSlice';
import { useAppDispatch } from '../../hooks';

export const BookingComponent = () => {
  const dispatch = useAppDispatch();
  const { bookingId } = useParams<{bookingId: string}>();
  const [booking, setBooking] = useState<Booking | null>(null);
  const [error, setError] = useState(null);
  const orders = booking?.orders ?? [];
  const categories = booking?.categories ?? [];

  useEffect(() => {
    const handleBooking = (booking: Booking) => {
      setBooking(booking);
      dispatch(resetCart({}));
    }

    if (!booking) {
      getBookingWithOrder(bookingId)
        .then(handleBooking)
        .catch(error => setError(error.message));
    }
  }, [booking, bookingId, dispatch]);

  const handleRefresh = () => {
    setError(null);
    getBookingWithOrder(bookingId)
      .then(setBooking)
      .catch(error => setError(error.message));
  }

  return (
    booking ?
      <>
        <BookingDetails booking={booking} />
        {orders.map((order, key) =>
          <div key={key}>
            <p key={key}>
              <FormattedMessage id="app.order.orderId" />: {order.id}
            </p>
            {order.attributes.status == null ?
              <a href={order.attributes.redirect_url}><FormattedMessage id="app.booking.pay" /></a> :
              <p><FormattedMessage id="app.booking.status" />: {order.attributes.status}</p>}
            {order.attributes.status === 'COMPLETED' &&
              <div>
                <p><FormattedMessage id="app.booking.congratulations" /> <FormattedMessage id="app.booking.pickUp" />
                </p>
                {/* <p>{booking.locations.map(location => location.attributes.name)}</p>//TODO check if locations and if array */}
                <ul>
                  {categories.map(category => <li><p>{category.attributes.name}</p></li>)}
                </ul>
                <p><FormattedMessage id="app.booking.moreDetails" /></p>
              </div>
            }
          </div>)}
        {orders.length === 0 && <>
          <FormattedMessage
            id="app.booking.notPaid"
            defaultMessage="Booking not paid yet"
          />
          <OrderForm />
        </>}
      </>
      : error ?
        <>
          <p>Error: {error}</p>
          <input type="button" onClick={handleRefresh} value="refresh" />
        </>
        : <p>
          <FormattedMessage id="app.booking.loading" />
        </p>
  );
}