import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    locale: 'pl',
};

export const localeSlice = createSlice({
    name: "locale",
    initialState,
    reducers: {
        selectLanguage: (state, action) => {
            state.locale = action.payload;
        },
    },
})

export const { selectLanguage } = localeSlice.actions;

export const selectLocale = (state: { locale: { locale: string; } }) => state.locale;

export default localeSlice.reducer;
