import {apiConfig} from "./config";
import {handleErrors} from "./bookings";

const CATEGORIES_PATH = "/categories"

export const getCategories = (queryParams: Record<string, string>) => {
    const url = new URL(window.location.protocol + apiConfig.baseUrl + CATEGORIES_PATH);
    if (queryParams) {
        Object.entries(queryParams).forEach(([key, value]) => {
            if (value) url.searchParams.append(key, value);
        })
    }

    return fetch(url.toString(), {
        headers: apiConfig.defaultHeaders,
    })
      .then(handleErrors)
      .then(response => response.json());
};

export const getCategory = (categoryId: string) => {
    return fetch(apiConfig.baseUrl + CATEGORIES_PATH + `/${categoryId}`, {
        headers: apiConfig.defaultHeaders,
    })
        .then(handleErrors)
        .then(response => response.json())
        .then(json => json.data);
};
