import React, { useState } from "react";
import LanguagePicker from "../language";
import { Badge, Button, Col, Drawer, Layout, Menu, Row } from 'antd';
import { MenuOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";

import { useAppSelector } from "../../hooks";
import { selectProducts } from "../../reducers/cartSlice";

const { Header } = Layout;

export const MainHeader = () => {
    const [visible, setVisible] = useState(false);
    const itemsInCartCount = useAppSelector(selectProducts).reduce((total, product) => { return total + product.amount }, 0);

    return (<Header className={'main-header'} /* zIndex={20} */>
        <Row>
            <Col xs={16} md={12}>
                <Link to="/" className="logo-name">ebike24.pl</Link>
            </Col>
            <Col xs={0} md={6} offset={2}>
                <Menu theme="light" mode="horizontal" defaultSelectedKeys={['1']}>
                    <Menu.Item key="1"><Link to="/">Home</Link></Menu.Item>
                    {/* <Menu.Item key="4"><Link to="/account">Moje konto</Link></Menu.Item> */}
                    <Menu.Item key="5"><Link to="/contact">Kontakt</Link></Menu.Item>
                    <Menu.Item key="3"
                        icon={<Badge count={itemsInCartCount}><ShoppingCartOutlined
                            style={{ color: 'black' }} /></Badge>}>
                        <Link to="/book" />
                    </Menu.Item>
                </Menu>
            </Col>
            <Col xs={4} md={2}>
                <LanguagePicker />
            </Col>
            <Col xs={4} md={0}>
                <Button type={'text'} onClick={() => setVisible(!visible)} icon={<MenuOutlined />}/>
            </Col>
        </Row>
        <Drawer
          placement={'top'}
          visible={visible}
          key={'top'}
          style={{top: '75px'}}
          zIndex={10}
          destroyOnClose={true}
          closable={false}
        >
            <Menu theme="light" mode="vertical" defaultSelectedKeys={['1']}>
                    <Menu.Item key="1"><Link onClick={() => setVisible(false)} to="/">Home</Link></Menu.Item>
                    <Menu.Item key="4"><Link onClick={() => setVisible(false)} to="/account">Moje konto</Link></Menu.Item>
                    <Menu.Item key="5"><Link onClick={() => setVisible(false)} to="/contact">Kontakt</Link></Menu.Item>
                    <Menu.Item key="3"
                        icon={<Badge count={itemsInCartCount}><ShoppingCartOutlined
                            style={{ color: 'black' }} /></Badge>}>
                        <Link onClick={() => setVisible(false)} to="/book" />
                    </Menu.Item>
                </Menu>
        </Drawer>
    </Header>);
}