import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCategories } from "../api/categories";
import { Resource } from "../api/common";

export type Category = {
    id: string;
    type: string;
    attributes: {
        company_id: string;
        name: string;
        description: string;
        price: {
            amount: string;
            currency: string;
        }
        currency: string;
        created_at: string;
        updated_at: string;
        photo: string;
        available: number;
    },
    relationships: {
        prices: {
            data: Resource[]
        }
    }
}

export enum Period {
    M60 = 'M60',
    M120 = 'M120',
    M180 = 'M180',
    M360 = 'M360', 
    M720 = 'M720',
    M1440 = 'M1440',
    M2880 = 'M2880'
}

export type CategoryWithPrices = Category & {prices: Price[]}

export type Price = {
    id: string;
    type: string;
    attributes: {
        period: Period;
        price: {
            amount: string;
            currency: 'PLN'
        }
    }
}

type CategoriesState = {
    categories: Category[],
    prices: Price[]
}

const initialState: CategoriesState = {
    categories: [],
    prices: []
};

export const searchCategories = createAsyncThunk(
    'categories/searchCategories',
    async (queryParams: {
        location: Number,
        pickup_date: string[]
    }) => {
        return await getCategories({
            sale_point_id: String(queryParams.location),
            startDate: queryParams.pickup_date[0],
            endDate: queryParams.pickup_date[1],
        });
    },
)

export const categoriesSlice = createSlice({
    name: "categories",
    initialState,
    reducers: {
        addCategories: (state, action) => {
            state.categories = [...action.payload.map((category: Category) => {
                return { ...category, id: category.id.toString() }
            })];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(searchCategories.fulfilled, (state, action) => {
            state.categories = [...action.payload.data.map((category: Category) => {
                return { ...category, id: category.id.toString() }
            })]
            state.prices =  [...action.payload.included.map((price: Price) => {
                return { ...price, id: price.id.toString() }
            })]
        })
    },
})

export const { addCategories } = categoriesSlice.actions;

export const selectCategories = (state: { categories: CategoriesState }) => state.categories;
export const selectCategoriesWithPrices = (state: { categories: CategoriesState }): CategoryWithPrices[] => {
    const categories = state.categories.categories;
    const categoriesWithPrices = categories.map(category => {
        const relatedPrices = category.relationships.prices.data.map(rel => String(rel.id));
        const prices = state.categories.prices.reduce((acc, price) => {if (relatedPrices.includes(price.id)) {acc.push(price);} return acc;}, [] as Price[]);
        return {...category, prices};
    })

    return categoriesWithPrices;
}
export const selectCategory = (categoryId: string) => (state: { categories: CategoriesState }): CategoryWithPrices | null => {
    const category = state.categories.categories.filter(category => category.id === categoryId)[0] ?? null;
    let categoryWithPrices = null;
    if (category) {
        const relatedPrices = category.relationships.prices.data.map(rel => String(rel.id));
        const prices = state.categories.prices.filter(price => relatedPrices.includes(price.id))
        categoryWithPrices = {...category, prices};
    }
    
    return categoryWithPrices;
};

export default categoriesSlice.reducer;
