import { createSlice } from "@reduxjs/toolkit";
import { Booking } from "../api/bookings";
import { Location } from '../api/getLocations';
import { Order } from "../api/orders";
import { RootState } from "../store";
import { Period, selectCategoriesWithPrices } from "./categoriesSlice";

export type Product = {
    id: string;
    amount: number;
}

export type Cart = {
    products: Product[],
    startDate: string | null,
    endDate: string | null,
    period: Period,
    location: Location | null,
    booking: Booking | null,
    order: Order | null
}

const initialState: Cart = {
    products: [],
    startDate: null,
    endDate: null,
    period: Period.M60,
    location: null,
    booking: null,
    order: null
};

export const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        addProduct: (state, action) => {
            const current = state.products.filter(product => product.id === action.payload)[0];
            const rest = state.products.filter(product => product.id !== action.payload);

            state.products = [{ id: action.payload, amount: (current?.amount ?? 0) + 1 }, ...rest];
        },
        removeProduct: (state, action) => {
            const current = state.products.filter(product => product.id === action.payload)[0];
            const rest = state.products.filter(product => product.id !== action.payload);

            if (!current || current.amount <= 1) {
                state.products = [...rest];
            } else {
                state.products = [{ id: action.payload, amount: current.amount - 1 }, ...rest];
            }
        },
        setLocation: (state, action) => {
            state.location = action.payload
        },
        setStartDate: (state, action) => {
            state.startDate = action.payload
        },
        setEndDate: (state, action) => {
            state.endDate = action.payload
        },
        setPeriod: (state, action) => {
            state.period = action.payload
        },
        setBooking: (state, action) => {
            state.products = [];
            state.startDate = null;
            state.endDate = null;
            state.location = null;
            state.booking = action.payload
        },
        setOrder: (state, action) => {
            state.order = action.payload
        },
        resetCart: (state, action) => {
            state.products = [];
            state.startDate = null;
            state.endDate = null;
            state.location = null;
            state.booking = null;
            state.order = null;
        }
    },
})

export const { addProduct, removeProduct, setLocation, setStartDate, setEndDate, setPeriod, setBooking, setOrder, resetCart } = cartSlice.actions;

export const selectProducts = (state: RootState) => state.cart.products.map(product => {
    const withPrices = selectCategoriesWithPrices(state);
    return {
        ...product,
        ...withPrices.filter(prod => prod.id === product.id)[0],
    }
});

export const selectCart = (state: RootState) => state.cart;
export const selectPeriod = (state: RootState) => state.cart.period;
export const selectCategoryInCart = (categoryId: string) => (state: RootState) => state.cart.products.find(product => product.id === categoryId);
export const selectBooking = (state: RootState) => state.cart.booking;
export const selectOrder = (state: RootState) => state.cart.order;

export default cartSlice.reducer;
