import { Booking } from "./bookings";
import { Resource } from "./common";
import { apiConfig } from "./config";

export const ORDERS_PATH = '/anonymous/orders';

export interface Order extends Resource {
    attributes: {
        redirect_url: string,
        status?: 'COMPLETED' | 'PENDING' | 'REJECTED',
        city: string,
        contract: boolean,
        country: string,
        created_at: string,
        customer_ip: string,
        email: string,
        external_id: string,
        firstName: string,
        id: string,
        idDocumentNumber: string,
        idDocumentType: string,
        lastName: string,
        phoneNumber?: string,
        street: string,
        tos: boolean,
        updated_at: string,
        zipCode: string,
        price: {
            amount: number,
            currency: string,
        },
    }
}

const resourceIdentifier = (resource: Resource) => {
    return {
        id: resource.id,
        type: resource.type
    };
}

export const orderBookingRequest = (booking: Booking) => {
    return JSON.stringify(
        {
            customer_ip: '127.0.0.1',
            booking: resourceIdentifier(booking),
            ...booking.attributes
        }
    );
}

export const orderBooking = (booking: Booking) => {
    return fetch(apiConfig.baseUrl + ORDERS_PATH, {
        method: 'POST',
        body: orderBookingRequest(booking),
        headers: apiConfig.defaultHeaders
    })
        .then(response => response.json())
        .then(json => json.data)
}
