import { Col, DatePicker, Form, FormInstance, Radio, Row, Select } from 'antd';
import moment from "moment";
import 'moment/locale/pl';
import React, { Component, RefObject } from "react";
import { FormattedMessage } from 'react-intl';
import { connect } from "react-redux";
import { getLocations, Location } from '../../api/getLocations';
import { setEndDate, setLocation, setStartDate, setPeriod } from "../../reducers/cartSlice";
import { Period, searchCategories } from "../../reducers/categoriesSlice";
import { RootState } from "../../store";

const formLayout = 'horizontal';
const formItemLayout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 12,
    },
};

type Props = {
    startDate: string | null;
    endDate: string | null;
    period: Period | null;
    location: Location | null;
    setLocation: (location: Location) => void;
    setStartDate: (date: string) => void;
    setEndDate: (date: string) => void;
    setPeriod: (period: Period) => void;
    searchCategories: (params: {
        location: Number,
        pickup_date: string[]
    }) => void;
    // formRef: FormInstance;
}

type State = {
    locations: Location[],
    isFetching: boolean;
}

class SearchBar extends Component<Props, State> {
    private formRef: RefObject<FormInstance<any>>;
    constructor(props: Props) {
        super(props);
        this.formRef = React.createRef<FormInstance>();
        this.state = {
            locations: [],
            isFetching: true,
        }
    }

    state: State = {
        locations: [],
        isFetching: true,
    }

    onReset = () => {
        this.formRef.current && this.formRef.current.resetFields();
    };

    radioOnChange(value: number) {
        const location = this.state.locations.find((location: Location) => location.id === value);
        if (location) {
            this.props.setLocation(location);
        }
    }

    componentDidMount() {
        if (this.props.startDate === null) { this.props.setStartDate(moment().format('Y-MM-DDTHH:mm:ss')); }
        if (this.props.endDate === null) { this.props.setEndDate(moment().add(1, 'hours').format('Y-MM-DDTHH:mm:ss')); }
        getLocations()
            .then((locations) => {
                this.setState({ ...this.state, isFetching: false, locations: [...locations] });
            })
            .catch(error => {
                console.warn(error);
            });
    }

    render() {
        return (
            <div className={'search-wrapper'}>
                <Row>
                    <Col span={24}>
                        <Form {...formItemLayout}
                            initialValues={{
                                layout: formLayout,
                                'pickup_place': this.props.location?.id || 0,
                                'pickup_date': this.props.startDate !== null ? moment(this.props.startDate) : moment(),
                                'rentalPeriod': this.props.period || Period.M60,
                            }}
                            layout={formLayout}
                            ref={this.formRef} name="control-ref"
                        // onFinish={this.onFinish}
                        >
                            <Form.Item
                                name="pickup_place"
                                label={
                                    <FormattedMessage
                                        id="app.search.pickupPlace"
                                        defaultMessage="Pickup place"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Radio.Group buttonStyle="solid"
                                    onChange={(event) => this.radioOnChange(event.target.value)}
                                    value={null}
                                    defaultValue={999}>
                                    {[...this.state.locations, { attributes: { name: "Dowóz" }, id: 999 }].map(item =>
                                        <Radio.Button key={item.id} value={item.id}>{item.attributes.name}</Radio.Button>)
                                    }
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                name="pickup_date"
                                label={
                                    <FormattedMessage
                                        id="app.search.pickupDate"
                                        defaultMessage="Pickup date"
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <DatePicker
                                    onChange={value => {
                                        if (value && value !== null) {
                                            this.props.setStartDate(value.format('Y-MM-DDTHH:mm:ss'));
                                        }
                                    }} onOk={value => console.log(value)} format="YYYY-MM-DD HH:mm" showTime={{ format: 'HH:mm' }} minuteStep={15} />
                            </Form.Item>
                            <Form.Item label={<FormattedMessage id={'app.search.rentalPeriod'} />} name="rentalPeriod" rules={[
                                {
                                    required: true,
                                    enum: Object.values(Period),
                                }
                            ]} initialValue={Period.M60} >
                                <Select<Period> placeholder={<FormattedMessage id={'app.search.rentalPeriodPlaceholder'} />} id="rentalPeriod" onChange={value => { if (this.props.startDate) { this.props.setEndDate(moment(this.props.startDate).add(parseInt(value.substring(1)), 'minutes').format('Y-MM-DDTHH:mm:ss')); this.props.setPeriod(value); } }}>
                                    <Select.Option value={Period.M60}>1 h</Select.Option>
                                    <Select.Option value={Period.M120}>2 h</Select.Option>
                                    <Select.Option value={Period.M180}>3 h</Select.Option>
                                    <Select.Option value={Period.M360}>6 h</Select.Option>
                                    <Select.Option value={Period.M720}>12 h</Select.Option>
                                    <Select.Option value={Period.M1440}>1 d</Select.Option>
                                    <Select.Option value={Period.M2880}>2 d</Select.Option>
                                </Select>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    location: state.cart.location,
    startDate: state.cart.startDate,
    endDate: state.cart.endDate,
    period: state.cart.period,
});

const mapDispatchToProps = { setLocation, setStartDate, setEndDate, setPeriod, searchCategories };

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);

