import { apiConfig } from "./config";
import { handleErrors } from "./bookings";

const LOCATIONS_PATH = "/locations";

export type Location = {
  id: number;
  type: string;
  attributes: {
    company_id: string;
    storage_id: string;
    name: string;
    location: string;
    created_at: string;
    updated_at: string;
  },
  relationships: {}
}

export const getLocations = (): Promise<Location[]> => {
  return fetch(apiConfig.baseUrl + LOCATIONS_PATH, {
    method: "GET",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  },
  )
    .then(handleErrors)
    .then(response => response.json())
    .then((data) => {
      return data.data;
    });
}

export default getLocations;