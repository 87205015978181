import { FormattedMessage } from "react-intl";
import { Button, DatePicker, Form, List, PageHeader, Select } from "antd";
import React, { useState } from "react";
import { Booking, createBooking } from "../../api/bookings";
import { OrderForm } from "../order";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  addProduct,
  removeProduct,
  resetCart,
  selectCart,
  selectProducts,
  setBooking,
  setStartDate,
  setEndDate,
  selectBooking,
  Product,
  setPeriod,
} from "../../reducers/cartSlice";
import { Location } from '../../api/getLocations';
import moment from 'moment';
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Price } from "../categories/Price";
import { BookingDetails } from "./BookingDetails";
import { useHistory } from "react-router-dom";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { CategoryWithPrices, Period } from "../../reducers/categoriesSlice";

const onFinishFailed = (errorInfo: string | ValidateErrorEntity<any>) => {
  console.log('failed finish:', errorInfo);
};

const calculateSum = (itemsInCart: (Product & CategoryWithPrices)[], period: Period): string | null => {
  try {
    return itemsInCart.reduce((total, item) =>{
      const price = item.prices.find(price => price.attributes.period === period)?.attributes.price.amount;
      if (price === undefined) {
        throw new Error('missing price for item');
      }
      return total + (parseInt(price) / 100) * item.amount;
    }, 0).toFixed(2);
  } catch(e) {
    return null;
  }
  
}

export const BookingForm = () => {
  const booking = useAppSelector(selectBooking);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const itemsInCart = useAppSelector(selectProducts);
  const { startDate, endDate, location, period } = useAppSelector(selectCart);
  const history = useHistory();

  const onFinish = (values: { pickup_date: moment.Moment, rentalPeriod: string }, itemsInCart: Product[], location: Location | null, successCallback: (booking: Booking) => void, failedCallback: (errorInfo: string | null) => void) => {
    if (location === null || startDate === null || endDate === null) {
      return failedCallback('No location selected');
    }
  
    createBooking(
      moment(startDate).toDate(),
      moment(endDate).toDate(),
      period,
      String(location.id),
      itemsInCart,
    )
      .then(response => {
        failedCallback(null);
        successCallback(response);
      })
      .catch(error => {
        failedCallback(error.message);
      });
  };

  const successCallback = (booking: Booking) => {
    dispatch(setBooking(booking));
  };

  return (
    <>
      {error && <p>{error}</p>}
      {!booking ?
        <>
          <PageHeader
            className="site-page-header"
            onBack={() => history.push('/')}
            title={<FormattedMessage id="app.bookings.yourBooking" />}
          />
          <List dataSource={itemsInCart} renderItem={item => <List.Item key={item.id}>
            <List.Item.Meta
              avatar={<img className="category-image" alt={item.attributes.name}
                src={item.attributes.photo ?? "https://via.placeholder.com/150"} />}
              description={
                <div>
                  <p><FormattedMessage id="app.bookings.price" />: <Price price={item.prices.find(price => price.attributes.period === period)?.attributes.price} /></p>
                  <p>{item.amount}<Button icon={<PlusCircleOutlined />}
                    onClick={() => dispatch(addProduct(item.id))} /><Button icon={<MinusCircleOutlined />}
                      onClick={() => dispatch(removeProduct(item.id))} /></p>
                </div>}
              title={item.attributes.name} />
          </List.Item>} />
          <p>
            <FormattedMessage id="app.search.totalValue" />:
            {calculateSum(itemsInCart, period) || 'NA'} PLN
          </p>
          <p>
            <FormattedMessage id="app.search.pickupPlace" />:
            {location && location.attributes.name}
          </p>
          <Form
            name="basic"
            initialValues={{
              remember: true,
              'pickup_date': moment(startDate) || moment(),
              'rentalPeriod': period || Period.M60,
            }}
            onFinish={(values) => onFinish(values, itemsInCart, location, successCallback, setError)}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="pickup_date"
              label={
                <FormattedMessage
                  id="app.search.pickupDate"
                  defaultMessage="Pickup date"
                />
              }
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker
                onChange={value => {
                  if (value && value !== null) {
                    dispatch(setStartDate(value.format('Y-MM-DDTHH:mm:ss')));
                  }
                }} format="YYYY-MM-DD HH:mm" showTime={{ format: 'HH:mm' }} minuteStep={15} />
            </Form.Item>
            <Form.Item label={<FormattedMessage id={'app.search.rentalPeriod'} />} name="rentalPeriod" rules={[
              {
                required: true,
                enum: Object.values(Period),
              }
            ]}  >
              <Select<string> placeholder={<FormattedMessage id={'app.search.rentalPeriodPlaceholder'} />} id="rentalPeriod" onChange={value => { if (startDate) { dispatch(setEndDate(moment(startDate).add(parseInt(value.substring(1)), 'minutes').format('Y-MM-DDTHH:mm:ss'))); dispatch(setPeriod(value)) } }}>
                <Select.Option value={Period.M60}>1 h</Select.Option>
                <Select.Option value={Period.M120}>2 h</Select.Option>
                <Select.Option value={Period.M180}>3 h</Select.Option>
                <Select.Option value={Period.M360}>6 h</Select.Option>
                <Select.Option value={Period.M720}>12 h</Select.Option>
                <Select.Option value={Period.M1440}>1 d</Select.Option>
                <Select.Option value={Period.M2880}>2 d</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                <FormattedMessage id={"app.booking.book"} />
              </Button>
            </Form.Item>
            <Form.Item>
              <Button type="dashed" onClick={() => dispatch(resetCart({}))}><FormattedMessage id={"app.booking.clearCart"} /></Button>
            </Form.Item>
          </Form>
        </>
        : <div>
          <BookingDetails booking={booking} />
          <OrderForm />
        </div>
      }
    </>
  );
}
