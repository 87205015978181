import { Statistic } from 'antd';
import React from 'react';
import * as PropTypes from "prop-types";

export const Price = (props: { price?: { amount: string; currency: string; } }) => {
    return props.price ? <Statistic title={''} value={`${(parseInt(props.price.amount) / 100).toFixed(2)} ${props.price.currency}`} /> : <div>NA</div>;
}

Price.propTypes = {
    price: PropTypes.shape({
        amount: PropTypes.string,
        currency: PropTypes.string,
    }),
};
