import { apiConfig } from "./config";
import { format } from "date-fns";
import { Resource } from "./common";

import { Product } from "../reducers/cartSlice";
import { Order } from "./orders";
import { Category, Period } from "../reducers/categoriesSlice";

export const BOOKINGS_PATH = '/anonymous/bookings';
export const START_DATE = 'start_date';
export const END_DATE = 'end_date';
export const PERIOD = 'period';
export const LOCATION = 'sale_point_id';
export const EMAIL = 'email';
export const CATEGORIES = 'categories';

export const BOOKING_DATE_FORMAT = 'yyyy-MM-dd HH:mm:ss';

export interface BookingAttributes {
    start_date: string,
    end_date: string,
}

export interface Booking extends Resource {
    attributes: BookingAttributes,
    relationships: {
        sale_point: {data: Resource[]},
        categories: {data: Resource[]},
        orders: {data: Resource[]},
        rentals: {data: Resource[]},
    },
    orders?: Order[],
    categories?: Category[],
}

export const formatBookingDate = (date: Date) => {
    return format(date, BOOKING_DATE_FORMAT)
}

export const createBookingRequest = (startDate: Date, endDate: Date, period: Period, location: string, itemsInCart: Product[]) => {
    return JSON.stringify({
        [START_DATE]: formatBookingDate(startDate),
        [END_DATE]: formatBookingDate(endDate),
        [PERIOD]: period,
        [LOCATION]: location,
        [CATEGORIES]: itemsInCart
    });
};

export const handleErrors = async (response: Response) => {
    if (!response.ok) {
        if (response.status === 422) {
            const errorMessage = await response.json();
            let errors;
            if ('errors' in errorMessage) {
                errors = Object.entries(errorMessage.errors).map(([_, errors]) => {
                    if (Array.isArray(errors)) {
                        return errors.join(', ');
                    }
                    return '';
                }).join(', ');
            }
            throw Error(errors);
        }
        throw Error(response.statusText ?? '');
    }
    return response;
}

export const getBookings = () => {
    return fetch(apiConfig.baseUrl + BOOKINGS_PATH, {
        headers: apiConfig.defaultHeaders,
    })
        .then(handleErrors)
        .then(response => response.json())
        .then(json => json.data)
        .catch(error => console.log(error));
};

export const getBooking = (bookingId: string) => {
    return fetch(apiConfig.baseUrl + BOOKINGS_PATH + `/${bookingId}`, {
        headers: apiConfig.defaultHeaders,
    })
        .then(handleErrors)
        .then(response => response.json())
        .then(json => json.data);
};

export const getBookingWithOrder = (bookingId: string) => {
    return fetch(apiConfig.baseUrl + BOOKINGS_PATH + `/${bookingId}`, {
        headers: apiConfig.defaultHeaders,
    })
        .then(handleErrors)
        .then(response => response.json())
        .then(json => {
            let booking = json.data;
            if (json.included) {
                json.included.forEach((record: Resource) => {
                    if (booking[record.type] === undefined) { booking[record.type] = []; }
                    booking[record.type].push(record);
                })
            }
            return booking;
        });
};

export const createBooking = (startDate: Date, endDate: Date, period: Period, location: string, itemsInCart: Product[]) => {
    return fetch(apiConfig.baseUrl + BOOKINGS_PATH, {
        method: 'POST',
        body: createBookingRequest(startDate, endDate, period, location, itemsInCart),
        headers: apiConfig.defaultHeaders,
    }).then(handleErrors)
        .then(response => response.json())
        .then(json => json.data);
};
