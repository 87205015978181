import React, { useEffect, useState } from "react";

import { Order, orderBooking } from "../../api/orders";
import { FormattedMessage, useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { resetCart, selectBooking, selectOrder, setOrder } from "../../reducers/cartSlice";

import { Button, Checkbox, Col, Form, Input, Row, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { OrderSummary } from "./OrderSummary";
import { Booking, BookingAttributes } from "../../api/bookings";
import { useHistory } from "react-router-dom";

const handleBookingOrderClick = (booking: Booking | null, getFormValues: () => Partial<BookingAttributes>, callback: (response: Order) => void) => () => {
  if (booking === null) {
    return;
  }
  orderBooking({ ...booking, attributes: { ...booking.attributes, ...getFormValues() } })
    .then(response => callback(response))
}

export const OrderForm = () => {
  const booking = useAppSelector(selectBooking);
  const order = useAppSelector(selectOrder);
  const dispatch = useAppDispatch();
  const [valid, setValid] = useState(false);
  const { formatMessage: f } = useIntl();
  const [form] = useForm();
  const history = useHistory();

  const onchange = () => {
    form.validateFields().then(() => {
      setValid(true);
    }).catch(() => {
      setValid(false);
    });
  };

  useEffect(() => {
    if (booking === null) {
      history.push('/');
    }
  }, [booking, history]);

  return (
    (!order) ?
      <Row>
        <Col xs={24} lg={12}>
          <Form form={form} validateTrigger={'onBlur'} onValuesChange={onchange}>
            <Form.Item label={f({ id: 'order.form.firstName' })} name="firstName"
              rules={[
                {
                  required: true,
                },
              ]}>
              <Input type="text" />
            </Form.Item>
            <Form.Item label={f({ id: 'order.form.lastName' })} name="lastName" rules={[
              {
                required: true,
              },
            ]}>
              <Input type="text" />
            </Form.Item>
            <Form.Item label={f({ id: 'order.form.phoneNumber' })} name="phoneNumber" rules={[
              {
                required: true,
              },
            ]}>
              <Input type="text" />
            </Form.Item>
            <Form.Item label={f({ id: 'order.form.email' })} name="email" rules={[
              {
                required: true
              }
            ]}>
              <Input type="email" />
            </Form.Item>
            <Form.Item label={f({ id: 'order.form.documentType' })} name="idDocumentType" rules={[
              { required: true,
                enum: ['personalId', 'passport', 'drivingLicence'],
               }
            ]} initialValue={'personalId'}>
              <Select placeholder={f({ id: 'order.form.idType' })} id="idType">
                <Select.Option value="personalId"><FormattedMessage id="order.form.personalId" /></Select.Option>
                <Select.Option value="passport"><FormattedMessage id="order.form.passport" /></Select.Option>
                <Select.Option value="drivingLicense"><FormattedMessage id="order.form.drivingLicence" /></Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label={f({ id: 'order.form.idDocumentNumber' })} name="idDocumentNumber" rules={[
              {
                required: true,
              },
            ]}>
              <Input type="text" />
            </Form.Item>
            <Form.Item label={f({ id: 'order.form.country' })} name="country" rules={[
              {
                required: true,
              },
            ]}>
              <Input type="text" />
            </Form.Item>
            <Form.Item label={f({ id: 'order.form.street' })} name="street" rules={[
              {
                required: true,
              },
            ]}>
              <Input type="text" />
            </Form.Item>
            <Form.Item  label={f({ id: 'order.form.zipCode' })} name="zipCode" rules={[
              {
                required: true,
                pattern: new RegExp('^\\d{2}(?:[-\\s]\\d{3})?$'),
              },
            ]}>
              <Input type="text" />
            </Form.Item>
            <Form.Item label={f({ id: 'order.form.city' })} name="city" rules={[
              {
                required: true,
              },
            ]}>
              <Input type="text" />
            </Form.Item>
            <Form.Item rules={[
              {
                validator(_, value) {
                  if (value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('You must accept Terms of Service!'));
                },
              },
            ]} name="tos" valuePropName="checked" initialValue={true}>
              <Checkbox type="checkbox" defaultChecked>{f({ id: 'order.form.tos' })}</Checkbox>
            </Form.Item>
            <Form.Item rules={[
              {
                validator(_, value) {
                  if (value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('You must accept the Contract!'));
                },
              },
            ]} name="contract" valuePropName="checked" initialValue={true}>
              <Checkbox>{f({ id: 'order.form.contract' })}</Checkbox>
            </Form.Item>
            <Form.Item>
              <Button type="primary" value={f({ id: "app.bookings.order" })} disabled={!valid || booking === null}
                onClick={handleBookingOrderClick(booking as Booking, form.getFieldsValue, payload => dispatch(setOrder(payload)))}><FormattedMessage id="app.booking.book" /></Button>
            </Form.Item>
            <Form.Item>
              <Button type="dashed" onClick={() => dispatch(resetCart({}))}><FormattedMessage id={"app.booking.cancel"} /></Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      :
      <div>
        <OrderSummary order={order} />
        <Button type="primary" onClick={() => window.location.href=order.attributes.redirect_url}><FormattedMessage id="app.booking.pay" /></Button>
        <br />
        <br />
        <Button type="dashed" onClick={() => dispatch(resetCart({}))}><FormattedMessage id={"app.order.cancelOrder"} /></Button>
      </div>
  );
}
